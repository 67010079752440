
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as NewsLetterServices from '@/services/NewsletterServices'


@Component
export default class BounceRateChart extends Vue {
    @Prop({ required: true }) from!: Date;
    @Prop({ required: true }) to!: Date;

    chartOptions = {
        chart: {
            id: 'cumulatieve-aantal-inschrijvingen'
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
        },
        dataLabels: {
            enabled: false
        },
    }

    series = [] as any[]

    loading = false;

    @Watch('from', { immediate: true })
    @Watch('to', { immediate: true })
    async onDateChange() {
        this.loading = true;
        const data = await NewsLetterServices.GetCumulativeSubscribers(this.from, this.to);

        this.series = [{
            name: 'Inschrijvingen',
            data: data.map((e: any) => [new Date(e.date).getTime(), e.count])
        }]
        this.loading = false;
    }
}
